import { PushNotifications, PushNotificationSchema } from '@capacitor/push-notifications';
import { Injectable } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';
import { AuthService } from './auth.service';
import { UpdatePushTokenDto } from '../dto/auth/update-push-token.dto';
import { PushTypeDto } from '../dto/notifications/push-type.dto';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(private navCtrl: NavController,
              private platform: Platform,
              public authService: AuthService) {
  }

  async initPush() {
    if (this.platform.is('hybrid')) {
      await this.initPushNotifications();
    }
  }

  async registerPush() {
    if (this.platform.is('hybrid')) {
      let permStatus = await PushNotifications.checkPermissions();

      if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions();
      }

      if (permStatus.receive !== 'granted') {
        throw new Error('User denied permissions!');
      }

      await PushNotifications.register();

      PushNotifications.addListener('registration', async token => {
        const updatePushTokenDto: UpdatePushTokenDto = {
          pushToken: token.value,
        };
        this.authService.updatePushTokenDto(updatePushTokenDto).pipe(take(1)).subscribe();
      });
    }
  }

  private initPushNotifications() {
    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        console.log('Error on push registration: ' + JSON.stringify(error));
      },
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        console.log('Push received: ' + JSON.stringify(notification));
      },
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed', async notification => {
      console.log('Push notification action performed', notification.actionId, notification.inputValue);
      const data = notification.notification.data;
      switch (parseInt(data.pushType, 10) as PushTypeDto) {
        case PushTypeDto.CATCHPHRASE:
          await this.navCtrl.navigateForward('/crush-word-detail/' + data.redirectValue);
          break;
        case PushTypeDto.PROFILE:
          await this.navCtrl.navigateRoot(['./home']);
          break;
        case PushTypeDto.GENERIC:
          await this.navCtrl.navigateRoot(['./home']);
          break;
      }
    });
  }
}

