import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable()
export class AppService {
  _darkmode: boolean;

  constructor(private storageService: StorageService) {
  }

  set darkmode(value: boolean) {
    this._darkmode = value;
    this.storageService.set('darkMode', value);
  }

  get darkmode(): boolean {
    return this._darkmode;
  }

}
