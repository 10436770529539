import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  public storage: Observable<string>;
  private storageSubject: BehaviorSubject<string>;

  constructor(public storageObject: Storage) {
    this.storageSubject = new BehaviorSubject<string>('');
    this.storage = this.storageSubject.asObservable();
    this.init().then();
  }

  async init() {
    await this.storageObject.create();
    this.storageSubject.next('created');
  }

// set a key/value
  async set(key: string, value: any): Promise<any> {
    try {
      await this.storageObject.set(key, value);
      return true;
    } catch (reason) {
      console.log(reason);
      return false;
    }
  }

// to get a key/value pair
  async get(key: string): Promise<any> {
    try {
      const result = await this.storageObject.get(key);
      if (result != null) {
        return result;
      }
      return null;
    } catch (reason) {
      console.log(reason);
      return null;
    }
  }

  async remove(key: string): Promise<any> {
    try {
      const result = await this.storageObject.remove(key);
      if (result != null) {
        return result;
      }
      return null;
    } catch (reason) {
      console.log(reason);
      return null;
    }
  }
}
