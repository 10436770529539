import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HandleError } from './http-error-handler.service';
import { Observable } from 'rxjs';
import { CreateCatchphraseCommentDto } from '../dto/catchphrase-comments/create-catchphrase-comment.dto';
import { CatchphraseCommentsDto } from '../dto/catchphrase-comments/catchphrase-comments.dto';
import { CatchphraseCommentDto } from '../dto/catchphrase-comments/catchphrase-comment.dto';
import { SERVER_URL } from '../../environments/environment';

@Injectable()
export class CatchphraseCommentsRepositoryService {
  catchphraseCommentsUrl = SERVER_URL + '/api/catchphrase-comments/';  // URL to web api
  handleError: HandleError;

  constructor(
    private http: HttpClient) {
  }

  getCatchphraseCommentsByCatchphrase(catchphraseId: string): Observable<CatchphraseCommentsDto> {
    return this.http.get<CatchphraseCommentsDto>(this.catchphraseCommentsUrl + catchphraseId);
  }

  post(catchphraseCommentId: string, catchphraseComment: CreateCatchphraseCommentDto): Observable<CatchphraseCommentDto> {
    return this.http.post<CatchphraseCommentDto>(this.catchphraseCommentsUrl + 'create/' + catchphraseCommentId,
      catchphraseComment);
  }

  delete(catchphraseId, catchphraseCommentId: string): Observable<boolean> {
    return this.http.delete<boolean>(this.catchphraseCommentsUrl + 'delete/' + catchphraseId + '/' + catchphraseCommentId);
  }

  likeCatchphraseComment(id: string): Observable<CatchphraseCommentDto> {
    return this.http.post<CatchphraseCommentDto>(this.catchphraseCommentsUrl + 'like/' + id, {});
  }

  unlikeCatchphraseComment(id: string): Observable<CatchphraseCommentDto> {
    return this.http.post<CatchphraseCommentDto>(this.catchphraseCommentsUrl + 'unlike/' + id, {});
  }
}



